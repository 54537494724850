import React from 'react';
import Colors from '../../../constants/Colors';
import StatusOptions from '../../../data/statusOptions.json';
import getCountryFlag from '../../../scripts/getCountryFlag';
import CountryCodes from '../../../data/countryISOcodes.json';
import CountryData from '../../../data/CountryCodes.json';
import { timeConverter } from '../../../scripts/getTime';
import {
  Edit,
  ImportContacts,
  CheckCircleOutline,
  Send,
  Replay,
  FilterVintage,
  CancelOutlined,
  PersonOff,
} from "@material-ui/icons";

const styles = {
  container: {
    flex: 1,
  },
  header: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    marginBottom: 10,
    padding: 5,
    backgroundColor: Colors.primary
  },
  headerText: {
    position: 'relative',
    flex: 1,
    marginLeft: 5,
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
    width: 150,
    padding: 5
  },
  editView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  editText: {
    marginRight: 5,
    fontWeight: 'normal',
    color: 'white',
  },
  data: {
    backgroundColor: 'white',
    position: 'relative',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    minHeight: 50,
    // borderWidth: 1,
    // borderColor: 'red',
    paddingBottom: 5,
  },
  centeredRow: {
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  leftAlignedRow: {
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  flag: {
    fontSize: 30,
    position: 'relative',
    marginHorizontal: 5,
  },
  countryName: {
    fontSize: 20,
    marginHorizontal: 5,
    textAlign: 'center',
    color: Colors.primary,
  },
  timestamp: {
    fontSize: 18,
    fontWeight: 'bold',
    color: Colors.primary,
    marginVertical: 4,
  },
  divider: {
    borderColor: Colors.primary,
    borderTopWidth: 1,
    position: 'relative',
    width: '100%',
    marginVertical: 5,
  },
  biblesSharedNum: {
    flex: 0,
    marginHorizontal: 10,
    fontSize: 20,
    fontWeight: 'bold',
    color: Colors.primary,
  },
  title: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    color: Colors.primary,
    textDecorationLine: 'underline',
    marginBottom: 5,
  },
  resourceNeeded: {
    position: 'relative',
    width: '100%',
    justifyContent: 'flex-start',
  },
  dataPoint: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  bold: {
    fontWeight: 'bold',
  },
};

const Icon = (status) => {
  if (status === 'accepted') {
    return <CheckCircleOutline style={{color: "white"}} />
  }
  if (status === 'delivered') {
    return <Send style={{color: "white"}} />
  }
  if (status === 'have') {
    return <ImportContacts style={{color: "white"}} />
  }
  if (status === 'rejected') {
    return <CancelOutlined style={{color: "white"}} />
  }
  if (status === 'unfinished') {
    return <Replay style={{color: "white"}} />
  }
  if (status === 'need') {
    return <FilterVintage style={{color: "white"}} />
  }
  if (status === 'vacant') {
    return <PersonOff style={{ color: "white" }} />;
  }
  return null;
}

const PinData = ({ markerObj }) => {
  const {
    countryCode,
    createdAt,
    regionData,
    resources,
    status,
  } = markerObj;

  const parsedRegionData =
    typeof regionData === 'string'
      ? JSON.parse(regionData)
      : regionData;
  const parsedResources =
    typeof resources === 'string' ? JSON.parse(resources) : resources;

  const locationString = parsedRegionData
    ? `${parsedRegionData.NAME_5 ? parsedRegionData.NAME_5 : ''}${
        parsedRegionData.NAME_5 ? ', ' : ''
      }${parsedRegionData.NAME_4 ? parsedRegionData.NAME_4 : ''}${
        parsedRegionData.NAME_4 ? ', ' : ''
      }${parsedRegionData.NAME_3 ? parsedRegionData.NAME_3 : ''}${
        parsedRegionData.NAME_3 ? ', ' : ''
      }${parsedRegionData.NAME_2 ? parsedRegionData.NAME_2 : ''}${
        parsedRegionData.NAME_2 ? ', ' : ''
      }${parsedRegionData.NAME_1 ? parsedRegionData.NAME_1 : ''}${
        parsedRegionData.NAME_1 ? ', ' : ''
      }${parsedRegionData.NAME_0}`
    : '';

  const localeLocationString =
    parsedRegionData && parsedRegionData.NL_NAME_1
      ? `${
          parsedRegionData.NL_NAME_5 ? parsedRegionData.NL_NAME_5 : ''
        }${parsedRegionData.NL_NAME_5 ? ', ' : ''}${
          parsedRegionData.NL_NAME_4 ? parsedRegionData.NL_NAME_4 : ''
        }${parsedRegionData.NL_NAME_4 ? ', ' : ''}${
          parsedRegionData.NL_NAME_3 ? parsedRegionData.NL_NAME_3 : ''
        }${parsedRegionData.NL_NAME_3 ? ', ' : ''}${
          parsedRegionData.NL_NAME_2 ? parsedRegionData.NL_NAME_2 : ''
        }${parsedRegionData.NL_NAME_2 ? ', ' : ''}${
          parsedRegionData.NL_NAME_1 ? parsedRegionData.NL_NAME_1 : ''
        }${parsedRegionData.NL_NAME_1 ? ', ' : ''}${
          parsedRegionData.NAME_0
        }`
      : '';

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        {Icon(status)}
        <span style={styles.headerText}>
          {StatusOptions[status].label}
        </span>
        {/* {status === 'rejected' ||
        status === 'need' ||
        status === 'unfinished' ? (
          <div style={styles.editView}>
            <span style={styles.editText}>
              [EDIT]
            </span>
            <Edit style={{color: "white"}} />
          </div>
        ) : null} */}
      </div>
      <div style={styles.data}>
        <div style={styles.centeredRow}>
          <span style={styles.timestamp}>
            {timeConverter(createdAt)}
          </span>
          {countryCode && <span style={styles.flag}>
            {getCountryFlag(CountryCodes[countryCode])}
          </span>}
        </div>
        <div style={styles.centeredRow}>
          <span style={styles.countryName}>{locationString}</span>
        </div>
        {!!localeLocationString && (
          <div style={styles.centeredRow}>
            <span style={styles.countryName}>
              {localeLocationString}
            </span>
          </div>
        )}
        {parsedResources && Object.keys(parsedResources).length > 0 && (
          <>
            <div style={styles.divider} />
            {status === 'need' ? (
              <div style={styles.resourceNeeded}>
                <span style={styles.title}>
                  {`Resource Needed: `}
                </span>
                <div style={styles.dataPoint}>
                  <span style={[styles.countryName, styles.bold]}>
                    {`Format: `}
                  </span>
                  <span style={styles.countryName}>
                    {parsedResources.formats.length > 0
                      ? parsedResources.formats.map((f, index) => {
                          if (index === 0) {
                            return f.toUpperCase();
                          }
                          return `, ${f.toUpperCase()}`;
                        })
                      : '?'}
                  </span>
                </div>
                <div style={styles.dataPoint}>
                  <span style={[styles.countryName, styles.bold]}>
                    {`Language: `}
                  </span>
                  <span style={styles.countryName}>
                    {parsedResources.language.split('--')[1]}
                  </span>
                  {parsedResources.language &&
                    parsedResources.language.split('--')[2] && (
                      <>
                        <span style={styles.countryName}>
                          (
                          {
                            Object.values(CountryData).find(
                              c =>
                                c.CountryID ===
                                parsedResources.language.split(
                                  '--',
                                )[2],
                            ).Name
                          }
                          )
                        </span>
                        <span style={styles.flag}>
                          {getCountryFlag(
                            parsedResources.language.split('--')[2],
                          )}
                        </span>
                      </>
                    )}
                </div>
                {!!parsedResources.description && (
                  <div style={styles.dataPoint}>
                    <span style={[styles.countryName, styles.bold]}>
                      {`Description: `}
                    </span>
                    <span style={styles.countryName}>
                      {parsedResources.description || ''}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div style={styles.leftAlignedRow}>
                <span style={styles.countryName}>
                  {`Bibles Shared: `}
                </span>
                <span style={styles.biblesSharedNum}>
                  {Object.values(parsedResources).reduce(
                    (a, b) => a + b,
                    0,
                  )}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PinData;
