// I still need to figure out a way to show which higher regions still have
// un-downloaded pins
const { getRegionData } = require("./getRegionData");

export const checkDownloadStatus = (downloads = {}, updates = {}) => {
  // console.log("[checkDownloadStatus] downloads", downloads);
  // console.log("[checkDownloadStatus] updates", updates);
  const statusObj = {};
  const updateKeys = Object.keys(updates);
  updateKeys.forEach((u) => {
    // console.log("updates[u]", updates[u].lastUpdate);
    getRegionData(u);
    // if never downloaded
    if (!downloads[u]) {
      // console.log("downloads[u]", downloads[u]);
      statusObj[u] = false;
      return;
    }
    // if downloaded...
    else {
      // ...after latest updates
      if ((downloads[u] || 0) > updates[u].lastUpdate) {
        // console.log(">>>>", (downloads[u] || 0) > updates[u].lastUpdate);
        statusObj[u] = true;
        return;
      }
      // ...before latest updates
      else {
        // console.log(">>>>", (downloads[u] || 0) > updates[u].lastUpdate);
        statusObj[u] = false;
        return;
      }
    }
  });
  const downloadsKeys = Object.keys(downloads);
  downloadsKeys.forEach((d) => {
    // console.log("statusObj[d]", statusObj[d]);
    getRegionData(d);
    // if downloaded before any new updates
    if (statusObj[d] === undefined) {
      statusObj[d] = true;
    }
  });
  return statusObj;
};