export const getRegionData = (regionId) => {
  const regionSplit = regionId.includes("-")
    ? regionId.split("-")
    : regionId.split(".");
  const regionLevel = regionSplit.length - 1;
  // console.log("getRegionData", regionSplit, regionLevel);
  if (regionLevel === 4) {
    return {
      regionId,
      regionLevel,
      parentRegions: [
        regionSplit[0],
        `${regionSplit[0]}.${regionSplit[1]}_1`,
        `${regionSplit[0]}.${regionSplit[1]}.${regionSplit[2]}_1`,
        `${regionSplit[0]}.${regionSplit[1]}.${regionSplit[2]}.${regionSplit[3]}_1`,
      ],
    };
  }
  if (regionLevel === 3) {
    return {
      regionId,
      regionLevel,
      parentRegions: [
        regionSplit[0],
        `${regionSplit[0]}.${regionSplit[1]}_1`,
        `${regionSplit[0]}.${regionSplit[1]}.${regionSplit[2]}_1`,
      ],
    };
  }
  if (regionLevel === 2) {
    return {
      regionId,
      regionLevel,
      parentRegions: [
        regionSplit[0],
        `${regionSplit[0]}.${regionSplit[1]}_1`,
      ],
    };
  }
  if (regionLevel === 1) {
    return {
      regionId,
      regionLevel,
      parentRegions: []
    }
  }
}