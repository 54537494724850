import React, { useState, useEffect, useMemo } from "react";
import { Link } from "gatsby";
import {SUPPORT} from "../../../constants/routes";
import { withFirebase } from "../../../utils/Firebase";
import { CircularProgress } from "@material-ui/core";
import Select from "../../atoms/Select";
import TopojsonKeys from "../../../data/TopojsonKeys.json";
import HorizontalBarChart from "../Report/atoms/HorizontalBar";
import Button from "../../atoms/Button";
import GoogleMap from "./GoogleMap";
import CountryLevelData from "../../../data/countryData.json";
import CountryISOCodes from "../../../data/countryISOcodes.json";
import getCountryFlag from "../../../scripts/getCountryFlag";
import Loading from "../../atoms/Loading";
import moment from "moment";
import {checkDownloadStatus} from '../../../scripts/checkDownloadStatus';

const colorOptions = [
  { value: "green", label: "Green" },
  { value: "yellow", label: "Yellow" },
  { value: "blue", label: "Blue" },
  { value: "red", label: "Red" }
];

const FacilitatorMaps = ({ firebase }) => {
  const { currentUser } = firebase ? firebase.auth : { currentUser: null };
  const [ministries, setMinistries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryData, setCountryData] = useState({});
  const [numOfCountries, setNumOfCountries] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRegion1, setSelectedRegion1] = useState();
  const [selectedRegion2, setSelectedRegion2] = useState();
  const [selectedRegion3, setSelectedRegion3] = useState();
  const [selectedRegion4, setSelectedRegion4] = useState();
  const [selectedRegion5, setSelectedRegion5] = useState();
  const [currentRegion, setCurrentRegion] = useState();
  const [countryReport, setCountryReport] = useState();
  const [region1Report, setRegion1Report] = useState();
  const [region2Report, setRegion2Report] = useState();
  const [region3Report, setRegion3Report] = useState();
  const [region4Report, setRegion4Report] = useState();
  const [region5Report, setRegion5Report] = useState();
  const [pins, setPins] = useState();
  const [ministryFilter, setMinistryFilter] = useState("all");
  const [colorFilter, setColorFilter] = useState("all");
  const [mapCenter, setMapCenter] = useState();
  const [loading, setLoading] = useState(false);
  const [countryLoading, setCountryLoading] = useState(true);
  const [updates, setUpdates] = useState();
  const [downloads, setDownloads] = useState();
  const [csvData, setCsvData] = useState();
  // const [bounds, setBounds] = useState()
  useEffect(() => {
    if (firebase && currentUser && !downloads) {
      getDownloadHistory();
    }
    if (currentUser && countries.length === 0) {
      getCountries();
    }
    if (currentUser && !updates && selectedCountry) {
      getUpdates(selectedCountry);
    }

  }, [currentUser, firebase, currentRegion, downloads]);

  const getDownloadHistory = async () => {
    const downloadHistory = await firebase
      .download(currentUser.uid)
      .get()
      .then((data) => data.data());;
    setDownloads(downloadHistory || {});
    return;
  }

  const getUpdates = async (country) => {
    console.log("[getUpdates] country", country);
    const updates = {};
    await firebase
      .updates()
      .where("regionId", ">=", country)
      .where("regionId", "<=", `${country}a`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
          updates[doc.id] = doc.data();
        });
      });
    setUpdates(updates);
  }

  const status = useMemo(() => {
    const status = checkDownloadStatus(
      downloads,
      updates
    );
    return status;
  }, [downloads, selectedCountry, updates]);

  const newDataRegions = useMemo(() => {
    console.log('useMemo', status, selectedCountry)
    const allRegions = Object.keys(status);
    return allRegions.filter((r) => !status[r])
  }, [status, selectedCountry, updates]);

  const lowestRegion = useMemo(() => {
    if (selectedCountry && TopojsonKeys[selectedCountry]) {
      return TopojsonKeys[selectedCountry].split("_")[2];
    }
    return null;
  }, [selectedCountry]);

  const reportData = useMemo(() => {
    if (!currentRegion) return null;
    if (currentRegion.split("-").length - 1 === 0) {
      return countryReport;
    }
    if (currentRegion.split("-").length - 1 === 1) {
      return region1Report;
    }
    if (currentRegion.split("-").length - 1 === 2) {
      return region2Report;
    }
    if (currentRegion.split("-").length - 1 === 3) {
      return region3Report;
    }
    if (currentRegion.split("-").length - 1 === 4) {
      return region4Report;
    }
    if (currentRegion.split("-").length - 1 === 5) {
      return region5Report;
    }
  }, [
    currentRegion,
    countryReport,
    region1Report,
    region2Report,
    region3Report,
    region4Report,
    region5Report,
  ]);

  // const pinsInBounds = useMemo(() => {
  //   if (!pins) return [];
  //   if (!bounds) return pins.locations;
  //   return pins.locations ? pins.locations.filter(l => {
  //     const { latitude, longitude } = l;
  //     let inBounds = false
  //   if (bounds &&
  //     latitude > bounds.lat.g &&
  //     latitude < bounds.lat.i &&
  //     longitude > bounds.lng.g &&
  //     longitude < bounds.lng.i
  //   ) {
  //     inBounds = true
  //   }
  //   return inBounds
  //   }) : []
  // }, [pins, bounds]);

  const processPinsForCsvData = (pins) => {
    if (!pins) return [];
    let csv = `latitude,longitude,color`;
    // const csv = [
    //   ["latitude", "longitude", "color"],
    // ]
    pins.forEach((pin) => {
      const { latitude, longitude, color } = pin;
      csv += `\n${latitude},${longitude},${color}`;
      // csv.push([`=${latitude}`, `=${longitude}`, color])
    });
    console.log(csv);
    setCsvData(csv);
  };

  const filteredLocations = useMemo(() => {
    if (!pins) return [];
    if (colorFilter === "all") {
      return pins.locations || [];
    }
    return pins.locations
      ? pins.locations.filter((l) => {
          let correctStatus = false;
          let correctMinistry = false;
          if (colorFilter === "all") {
            correctStatus = true;
          }
          if (l.color === colorFilter) {
            correctStatus = true;
          }
          return correctStatus;
        })
      : [];
  }, [pins, colorFilter]);

  const getCountries = async () => {
    const { regions } = await firebase
      .user(currentUser.uid)
      .get()
      .then((data) => data.data());

    const isAdmin = regions.indexOf("all") !== -1;

    if (isAdmin) {
      const { activeRegions } = await firebase
        .report("global")
        .get()
        .then((snapshot) => snapshot.data())
        .catch((err) => console.log(err));
      console.log("activeRegions", activeRegions);
      getCountriesData(activeRegions);
      return;
    }
    await firebase
      .ministries()
      .where("facilitators", "array-contains", currentUser.uid)
      .get()
      .then((querySnapshot) => {
        let array = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          array.push(doc.data());
        });
        setMinistries(array);
        const countries = [...new Set(array.map((m) => m.country))];
        console.log(array);
        getCountriesData(countries);
        setCountries(countries);
      })
      .catch((err) => console.log(err));
  };

  const getCountryData = async (c) => {
    const data = await firebase
      .regionNameData(c)
      .once("value")
      .then((snapshot) => snapshot.val());
    return data;
    // const newObj = Object.assign(countryData);
    // newObj[c] = data;
    // console.log("newObj", newObj);
    // setCountryData(newObj);
    // setNumOfCountries(Object.keys(newObj).length);
    // return data;
  };

  const getCountriesData = async (countries) => {
    // console.log("[getCountriesData]", countries);
    const cDataObj = {};
    countries.forEach(async (c, cIndex) => {
      // console.log(c);
      const cData = await getCountryData(c);
      // console.log("cData", cData);
      cDataObj[c] = cData;
      // console.log("[setCountryData] cDataObj", cDataObj);
      setCountryData(cDataObj);
      if (cIndex === countries.length - 1) {
        setCountryLoading(false);
      }
    });
    // setCountryData(cDataObj);
    setNumOfCountries(Object.keys(cDataObj).length);
    setCountries(countries);
  };

  const getReportData = async (regionId) => {
    // console.log("[getRegionOptions]", regionId);
    const reportId = regionId.replace(/\-/g, ".");
    const regionLevel = regionId.split("-").length - 1;
    const reportData = await firebase
      .report(reportId)
      .get()
      .then((snapshot) => snapshot.data());
    // console.log("reportData", reportData);
    if (!reportData) {
      setCountryReport(null);
      setCurrentRegion(null);
    }
    if (regionLevel === 0 && reportData) {
      setCountryReport(reportData);
      setCurrentRegion(regionId);
      return;
    }
    if (regionLevel === 1 && reportData) {
      setRegion1Report(reportData);
      setCurrentRegion(regionId);
      return;
    }
    if (regionLevel === 2 && reportData) {
      setRegion2Report(reportData);
      setCurrentRegion(regionId);
      return;
    }
    if (regionLevel === 3 && reportData) {
      setRegion3Report(reportData);
      setCurrentRegion(regionId);
      return;
    }
    if (regionLevel === 4 && reportData) {
      setRegion4Report(reportData);
      setCurrentRegion(regionId);
      return;
    }
    if (regionLevel === 5 && reportData) {
      setRegion5Report(reportData);
      setCurrentRegion(regionId);
      return;
    }
  };

  const getPins = async (regionId) => {
    setLoading(true);
    const reportId = regionId.replace(/\-/g, ".");
    console.log("getPins", reportId);
    const transformedPins = await firebase
      .regionPins(reportId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const { pins } = doc.data();
          return pins.map(pin => {
            const split = pin.split(",");
            return {
              latitude: +split[0],
              longitude: +split[1],
              uid: split[2],
              color: split[3]
            }
          })
        }
        return [];
      });
    if (transformedPins.length > 0) {
      setMapCenter({
        lat: transformedPins[0].latitude,
        lng: transformedPins[0].longitude,
      });
    }
    setPins({ locations: transformedPins, regionId });
    setLoading(false);
    processPinsForCsvData(transformedPins);
    // setUpdatedPins(updatedPins+1);
  };

  const getRegionOptions = (region, previousReport) => {
    const filteredRegions = Object.keys(countryData[selectedCountry]).filter(
      (reg) =>
        reg.split("-").length === region.split("-").length + 1 &&
        reg.includes(region.split("_")[0])
    );
    const sortedRegions = filteredRegions.sort((a, b) => {
      const labelA =
        countryData[selectedCountry][a].data[
          `NAME_${a.split("-").length - 1}`
        ] || `NAME_${a.split("-").length - 1}`;
      const labelB =
        countryData[selectedCountry][b].data[
          `NAME_${b.split("-").length - 1}`
        ] || `NAME_${b.split("-").length - 1}`;
      return labelA.toLowerCase() < labelB.toLowerCase()
        ? -1
        : labelA.toLowerCase() > labelB.toLowerCase()
        ? 1
        : 0;
    });
    // console.log("newDataRegions", newDataRegions, selectedCountry, status);
    return sortedRegions.map((r) => {
      const rSplit = r.split("_");
      const regionString = rSplit[0].split("-").join(".");
      const isUpdated = newDataRegions.find((r) => {
        const matches = r.includes(regionString + "_") || r.includes(regionString + ".");
        return matches;
      });
      return {
        value: r,
        label:
          `${
            countryData[selectedCountry][r].data[
              `NAME_${r.split("-").length - 1}`
            ]
          }${isUpdated ? "(~)" : ""}` ||
          `NAME_${r.split("-").length - 1}${isUpdated ? " (~)" : ""}`,
        disabled: previousReport
          ? previousReport.activeRegions.indexOf(r.replace(/-/g, ".")) === -1
          : true,
      };
    });
  };

  const countryOptions = useMemo(() => {
    const sorted = countries
      .sort((a, b) => {
        const aName = CountryLevelData[a].NAME_LONG;
        const bName = CountryLevelData[b].NAME_LONG;
        return aName > bName ? 1 : -1;
      })
      .map((c) => c);
    return sorted.map((c) => {
      return {
        value: c,
        label: `${getCountryFlag(CountryISOCodes[c])} ${
          CountryLevelData[c].NAME_LONG
        }`,
      };
    });
  }, [countries, countryData]);

  const getDownloadObj = (region) => {
    const regionSplit = currentRegion.split("-")
    const timestamp = moment().utc().valueOf();
    const newObj = {
      [regionSplit.join(".")]: timestamp,
    };
    if (regionSplit.length === 5) {
      const newRegionId4 = `${regionSplit[0]}.${regionSplit[1]}.${regionSplit[2]}.${regionSplit[3]}_1`;
      const newRegionId3 = `${regionSplit[0]}.${regionSplit[1]}.${regionSplit[2]}_1`;
      const newRegionId2 = `${regionSplit[0]}.${regionSplit[1]}_1`;
      const newRegionId1 = `${regionSplit[0]}`;
      newObj[newRegionId4] = timestamp;
      newObj[newRegionId3] = timestamp;
      newObj[newRegionId2] = timestamp;
      newObj[newRegionId1] = timestamp;
    }
    if (regionSplit.length === 4) {
      const newRegionId3 = `${regionSplit[0]}.${regionSplit[1]}.${regionSplit[2]}_1`;
      const newRegionId2 = `${regionSplit[0]}.${regionSplit[1]}_1`;
      const newRegionId1 = `${regionSplit[0]}`;
      newObj[newRegionId3] = timestamp;
      newObj[newRegionId2] = timestamp;
      newObj[newRegionId1] = timestamp;
    }
    if (regionSplit.length === 3) {
      const newRegionId2 = `${regionSplit[0]}.${regionSplit[1]}_1`;
      const newRegionId1 = `${regionSplit[0]}`;
      newObj[newRegionId2] = timestamp;
      newObj[newRegionId1] = timestamp;
    }
    if (regionSplit.length === 2) {
      const newRegionId1 = `${regionSplit[0]}`;
      newObj[newRegionId1] = timestamp;
    }
    return newObj;
  }
  // console.log('filteredLocations', filteredLocations.length)
  // console.log('pinsInBounds', pinsInBounds.length)
  // console.log('downloads', downloads);
  // console.log('updates', updates);
  // console.log("status", status);
  console.log("pins", pins);

  return (
    <div className="home container">
      <div className="ministry__details">
        <h1 className="ministry__title">Maps</h1>
      </div>
      <p>
        To keep the costs of our database down the maps are only available for
        the lowest regions in a country. For example, in the United States the
        lowest region is the county level. So pins are available on the map at
        the county level.
      </p>
      {countryLoading && (
        <div className="map_loading">
          <Loading />
          <p>Loading...</p>
          <p>(Retrieving country data)</p>
        </div>
      )}
      <div className="map_select_div" key="countrySelect-1">
        {!countryLoading && countries.length > 0 && countryData && (
          <div>
            <Select
              labelName="Country"
              options={countryOptions}
              label="Select Country"
              value={selectedCountry}
              setValue={(value) => {
                setSelectedCountry(value);
                getReportData(value);
                setSelectedRegion1("");
                setSelectedRegion2("");
                setSelectedRegion3("");
                setSelectedRegion4("");
                setSelectedRegion5("");
                setMapCenter(null);
                getUpdates(value);
                setCurrentRegion(null);
                // setBounds(null)
              }}
            />
          </div>
        )}
        {+lowestRegion >= 1 &&
        countryReport &&
        selectedCountry &&
        countryData &&
        countryData[selectedCountry] ? (
          <div key="regionSelect-1">
            <Select
              labelName="Region 1"
              options={getRegionOptions(selectedCountry, countryReport)}
              label="Select Region"
              value={selectedRegion1}
              setValue={(value) => {
                setSelectedRegion1(value);
                getReportData(value);
                setSelectedRegion2("");
                setSelectedRegion3("");
                setSelectedRegion4("");
                setSelectedRegion5("");
                setMapCenter(null);
                // setBounds(null)
              }}
            />
          </div>
        ) : selectedCountry && !countryReport ? (
          <p>Sorry, there is no data yet for this country</p>
        ) : null}
        {+lowestRegion >= 2 &&
          region1Report &&
          selectedCountry &&
          countryData &&
          selectedRegion1 && (
            <div key="regionSelect-2">
              <Select
                labelName="Region 2"
                options={getRegionOptions(selectedRegion1, region1Report)}
                label="Select Region"
                value={selectedRegion2}
                setValue={(value) => {
                  setSelectedRegion2(value);
                  getReportData(value);
                  setSelectedRegion3("");
                  setSelectedRegion4("");
                  setSelectedRegion5("");
                  setMapCenter(null);
                  // setBounds(null)
                }}
              />
            </div>
          )}
        {+lowestRegion >= 3 &&
          region2Report &&
          selectedCountry &&
          countryData &&
          selectedRegion2 && (
            <div key="regionSelect-3">
              <Select
                labelName="Region 3"
                options={getRegionOptions(selectedRegion2, region2Report)}
                label="Select Region"
                value={selectedRegion3}
                setValue={(value) => {
                  setSelectedRegion3(value);
                  getReportData(value);
                  setSelectedRegion4("");
                  setSelectedRegion5("");
                  setMapCenter(null);
                  // setBounds(null)
                }}
              />
            </div>
          )}
        {+lowestRegion >= 4 &&
          region3Report &&
          selectedCountry &&
          countryData &&
          selectedRegion3 && (
            <div key="regionSelect-4">
              <Select
                labelName="Region 4"
                options={getRegionOptions(selectedRegion3, region3Report)}
                label="Select Region"
                value={selectedRegion4}
                setValue={(value) => {
                  setSelectedRegion4(value);
                  getReportData(value);
                  setSelectedRegion5("");
                  setMapCenter(null);
                  // setBounds(null)
                }}
              />
            </div>
          )}
        {+lowestRegion >= 5 &&
          region4Report &&
          selectedCountry &&
          countryData &&
          selectedRegion4 && (
            <div key="regionSelect-5">
              <Select
                labelName="Region 5"
                options={getRegionOptions(selectedRegion4, region4Report)}
                label="Select Region"
                value={selectedRegion5}
                setValue={(value) => {
                  setSelectedRegion5(value);
                  getReportData(value);
                  setMapCenter(null);
                  // setBounds(null)
                }}
              />
            </div>
          )}
      </div>
      {reportData && (
        <div
          className="horizontalChart"
          style={{
            maxWidth: 800,
            margin: "auto",
          }}
        >
          <HorizontalBarChart data={reportData} />
        </div>
      )}
      {currentRegion && +lowestRegion === currentRegion.split("-").length - 1 && (
        <div
          style={{
            maxWidth: 500,
            margin: "auto",
          }}
        >
          <Button
            text={loading ? <CircularProgress size={14} /> : "Show pins on map"}
            onClick={() => getPins(currentRegion)}
          />
        </div>
      )}
      <hr
        style={{
          backgroundColor: "black",
        }}
      />
      {pins && currentRegion === pins.regionId && (
        <div
          style={{
            maxWidth: 500,
            margin: "auto",
          }}
        >
          <a
            href={`data:text/csv;charset=utf-8,${csvData}`}
            download={`${currentRegion} (${
              countryData[selectedCountry][currentRegion].data[
                `NAME_${currentRegion.split("-").length - 1}`
              ]
            }).csv`}
            style={{ textDecoration: "none" }}
            onClick={async () => {
              const newDownloadsObj = getDownloadObj(currentRegion);
              if (downloads) {
                const newDownloads = Object.assign(downloads, newDownloadsObj);
                await firebase.download(currentUser.uid).set(newDownloads);
                getDownloadHistory();
              } else {
                await firebase.download(currentUser.uid).set(newDownloadsObj);
                getDownloadHistory();
              }
            }}
          >
            <Button text={`Export/Download ${pins.locations.length} pins`}>
              Export/Download {pins.locations.length} pins
            </Button>
          </a>
        </div>
      )}
      <p style={{ color: "red", textAlign: "center" }}>
        Please do not download more than you need. I'm trying to keep this tool
        free.
        <br />
        If you'd like to donate for this project, you can do so by{" "}
        <Link to={SUPPORT}>clicking here.</Link>
      </p>

      {pins && currentRegion === pins.regionId && (
        <div>
          <p>
            Note: Filtering data significantly slows down the website when there
            are more than 2,000 pins on the map.
          </p>
          <div className="map_select_div">
            <div>
              <Select
                labelName="Filter by Ministry"
                options={[
                  ...[{ value: "all", label: "All" }],
                  ...ministries.map((m) => {
                    return { value: m.ministryId, label: m.name };
                  }),
                ]}
                label="Select Ministry"
                value={ministryFilter}
                setValue={setMinistryFilter}
              />
            </div>
            <div>
              <Select
                labelName="Filter by Color"
                options={[...[{ value: "all", label: "All" }], ...colorOptions]}
                label="Select Status"
                value={colorFilter}
                setValue={setColorFilter}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: 500,
              marginTop: 20,
              marginBottom: 50,
              display: "flex",
              padding: 10,
            }}
          >
            <GoogleMap
              locations={filteredLocations || []}
              containerStyle={{
                width: "100%",
                height: "100%",
              }}
              setMapCenter={setMapCenter}
              center={
                !!mapCenter
                  ? mapCenter
                  : {
                      lat: filteredLocations[0]
                        ? filteredLocations[0].latitude
                        : 30.069275289101196,
                      lng: filteredLocations[0]
                        ? filteredLocations[0].longitude
                        : 31.236833299862873,
                    }
              }
              // setBounds={setBounds}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withFirebase(FacilitatorMaps);
