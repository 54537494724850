import React, { useState, useRef } from "react"
import {
  GoogleMap,
  LoadScript,
  MarkerClusterer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import PinData from "./PinData"

const getPinColor = (status) => {
  if (status === "accepted" || status === "delivered" || status === "have") {
    return "green"
  }
  if (status === "unfinished") {
    return "yellow"
  }
  if (status === "need") {
    return "blue"
  }
  if (status === "vacant") {
    return "grey"
  }
  return "red"
}

const CustomGoogleMap = ({
  locations,
  containerStyle,
  setMapCenter,
  center,
  setBounds,
}) => {
  const mapRef = useRef(null)
  const [markerCoords, setMarkerCoords] = useState()
  const [markerData, setMarkerData] = useState()

  function handleLoad(map) {
    mapRef.current = map
  }

  function handleCenter() {
    if (!mapRef.current) return

    const { lat, lng } = mapRef.current.getCenter().toJSON()
    if (lat !== center.lat || lng !== center.lng) {
      setMapCenter({ lat, lng })
    }
    // const bounds = mapRef.current.getBounds()
    // setBounds({
    //   lat: bounds.Ra,
    //   lng: bounds.La
    // })
  }

  return (
    <LoadScript googleMapsApiKey="AIzaSyBA0E5ee7tbgSg74oHtPSX-PeLthP27AOQ">
      <GoogleMap
        onLoad={handleLoad}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={9}
        onCenterChanged={handleCenter}
        onZoomChanged={handleCenter}
        // mapTypeId='satellite'
        // mapOptions={{
        //   styles: [{
        //     featureType: "road.local",
        //     elementType: "labels"
        //   }]
        // }}
      >
        {/* {!!markerCoords && (
          <InfoWindow
            // style={{marginBottom: 50}}
            pixelOffset={50}
            position={markerCoords}
            onCloseClick={() => {
              setMarkerCoords(null)
              setMarkerData(null)
            }}
          >
            {markerData ? (
              <PinData markerObj={markerData} />
            ) : (
              <p>Sorry, no pin data</p>
            )}
          </InfoWindow>
        )} */}
        <MarkerClusterer
          // gridSize={80}
          maxZoom={17}
          minimumClusterSize={10}
        >
          {(clusterer) =>
            locations.map((location) => {
              const { uid, latitude, longitude, color } = location
              return (
                <Marker
                  key={uid}
                  position={{ lat: latitude, lng: longitude }}
                  clusterer={clusterer}
                  icon={`http://maps.google.com/mapfiles/ms/icons/${color}.png`}
                  // onClick={(event) => {
                  //   setMarkerCoords({
                  //     lat: event.latLng.lat(),
                  //     lng: event.latLng.lng(),
                  //   });
                  //   setMarkerData(location);
                  // }}
                />
              );
            })
          }
        </MarkerClusterer>
      </GoogleMap>
    </LoadScript>
  )
}

export default CustomGoogleMap
