import React from "react";
import { compose } from "recompose";
import Layout from "../utils/layout";
import { withAuthorization, withEmailVerification } from "../utils/Session";
import FacilitatorMaps from "../components/scenes/FacilitatorMaps";

const condition = (authUser) => !!authUser;
const FacilitatorMapsPage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(FacilitatorMaps);

export default () => {
  return (
    <Layout>
      <FacilitatorMapsPage />
    </Layout>
  );
};
